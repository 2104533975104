import React from "react"
import { Layout, Parallax } from "@components/layout"
import { Breadcrumbs } from "@components/shared"
import { ContactForm } from "@components/standard"
import contactIcon from "./img/contact-me.png"

import {
  page__wrapper,
  page__content,
  contact,
  contact__header,
  contact__links,
  contact__links__single,
  contact__icon,
} from "./styles/contact.module.scss"

const Contact = ({ pageContext }) => {
  const { page } = pageContext

  return (
    <Layout
      animation={false}
      seo={pageContext.page.seo}
      siteMetaData={pageContext.page.siteMetaData}
    >
      <Parallax height={400} image={page.featuredImage}
      title={"Zawsze warto pogadać"}>
      </Parallax>
      <div className={page__wrapper}>
        <Breadcrumbs
          elements={[
            {
              label: page.title,
              url: `/${page.slug}/`,
            },
          ]}
        />
        <div className={page__content}>
          <div className={contact__icon}>
            <img src={contactIcon} alt="" />
          </div>
          <div className={contact}>
            <h5 className={contact__header}>Skontaktuj się ze mną:</h5>
            <ul>
              <li>
                <strong>Jeśli szukasz swojego miejsca w IT</strong>
              </li>
              <li>
                <strong>Jeśli szukasz mentora na swojej ścieżce rozwoju</strong>
              </li>
              <li>
                <strong>Jeśli nie masz pomysłów do portfolio</strong>
              </li>
              <li>
                <strong>
                  Jeśli nie masz wystarczająco praktyki lub brak Ci wyzwań w
                  rozwoju
                </strong>
              </li>
              <li>
                <strong>
                  Jeśli Twoj aktualna praca Cię męczy i chcesz zmienić ją na
                  lepszą
                </strong>
              </li>
            </ul>

            <h5 className={contact__header}>
              Czasem jedno usłyszane zdanie od mentora otwiera Ci bramkę w
              głowie i pozwala zrozumieć temat lepiej niż czytając z
              dokumentacji.
            </h5>

            <div className={contact__links}>
              <div className={contact__links__single}>
                <i class="fab fa-facebook-square"></i>
                <h5>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/localhost40310"
                  >
                    {" "}
                    Localhost Group
                  </a>
                </h5>
              </div>

              <div className={contact__links__single}>
                <i class="far fa-envelope"></i>
                <h5>
                  <a href="mailto:contact@localhost-group.com">
                    {" "}
                    contact@localhost-group.com
                  </a>
                </h5>
              </div>

              <div className={contact__links__single}>
                <i class="fas fa-mobile-alt"></i>
                <h5>
                  <a href="tel:+48535000959">(+48) 535 000 959</a>
                </h5>
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
    </Layout>
  )
}

export default Contact
